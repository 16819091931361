import developerImage from "./assets/images/developer.jpg"

const logotext = "KWABENA";
const meta = {
  title: "Kwabena (N. A.) Asante",
  description: "I’m Kwabena Asante, a Software Developer currently located in Canada",
};

const introdata = {
  title: "I’m Kwabena (N. A.) Asante",
  animated: {
    first: "I love coding",
    third: "I architect systems",
    second: "I contribute to open source",
  },
  description: "I believe in openly discussing methodologies and lessons learned from developing solutions. This not only enriches the learning experience but also saves time for countless others who may follow in the same footsteps—be it a handful or a multitude.",
  your_img_url: developerImage,
};

const dataabout = {
  title: "Professional summary",
  aboutme: "I am an AWS Certified Developer with extensive expertise in designing and implementing solutions, ranging from small-scale desktop applications to robust, large-scale cloud-native architectures. Adept at leveraging diverse software engineering skills to innovate and automate processes, enhancing performance and security. Committed to continuous learning and employing cutting-edge technologies to deliver high-impact solutions.",
};
const worktimeline = [{
  jobtitle: "Backend Lead",
  where: "mPharma",
  date: "2019 - 2024",
},
{
  jobtitle: "Software Engineer",
  where: "ESW Capital",
  date: "2018 - 2019",
},
{
  jobtitle: "Consultant",
  where: "Ampersand Technologies Ltd",
  date: "2018 - 2019",
},
{
  jobtitle: "Software Engineer",
  where: "Bsystems Limited",
  date: "2014 - 2018",
},
];

const skills = [{
  name: "Python",
  value: 100,
},
{
  name: "Typescript /  Javascript",
  value: 100,
},
{
  name: "C#",
  value: 100,
},
{
  name: "SQL",
  value: 100,
},
{
  name: "Golang",
  value: 100,
},
{
  name: "AWS Services",
  value: 100,
},
];

const services = [{
  title: "AWS Certified Developer - Associate",
  description: "Amazon Web Services Training and Certification",
},
{
  title: "BSc Mathematics",
  description: "Kwame Nkrumah University of Science And Technology, Ghana",
},
];

const dataportfolio = [{
  img: "https://kaypee90.github.io/img-hub/zebrok.jpeg",
  description: "Zebrok: Brokerless task queue for python based on 0Mq",
  link: "https://github.com/kaypee90/zebrok",
},

{
  img: "https://kaypee90.github.io/img-hub/openstds.jpeg",
  description: "Awesome Open Standards: List of popular open standards.",
  link: "https://github.com/kaypee90/awesome-open-standards",
},
{
  img: "https://kaypee90.github.io/img-hub/depbot.jpeg",
  description: "Dependabot Wizard: Streamlines the setup of basic Dependabot configurations for Github projects.",
  link: "https://github.com/kaypee90/dependabot-wizard",
},
{
  img: "https://kaypee90.github.io/img-hub/query.jpeg",
  description: "Exp. Query Accelerator: Experimental server for caching database fetch queries.",
  link: "https://github.com/kaypee90/exp-query-accelerator",
},
{
  img: "https://kaypee90.github.io/img-hub/dhc.jpeg",
  description: "DHC Server: Go application designed to consume data captured by the Django Health Check Monitoring library via a RESTful API.",
  link: "https://github.com/kaypee90/dhc-server",
},
];

const socialprofils = {
  github: "https://github.com/kaypee90",
  linkedin: "https://www.linkedin.com/in/kwabena-asante-9b006477",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  socialprofils,
  logotext,
};
